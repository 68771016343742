<template>
  <div class="box-wrap">
    <div class="top-box">
      <date-search @onsearch="handleSearch"></date-search>
      <top-date :deviceNum="deviceNum"></top-date>
    </div>
    <div class="chart-box">
      <!-- 顶部4个 -->
      <div class="top-num-box">
        <div class="over-num-item">
          <div class="icon-box box1">
            <img class="icon" src="@/assets/img/page/index_top_icon1.png" alt />
          </div>
          <div class="o-text">
            <div class="name">累计产量 Total output</div>
            <div class="name">片 Piece</div>
            <dv-digital-flop
              class="o-num"
              :config="handleDigitalFlop(topTotalList.reality_actual_count)"
            />
          </div>
        </div>

        <div class="over-num-item">
          <div class="icon-box box2">
            <img class="icon" src="@/assets/img/page/index_top_icon2.png" alt />
          </div>
          <div class="o-text">
            <div class="name">累计运行时间 Total run time</div>
            <div class="name">小时 : 分钟 H : mm</div>
            <div class="o-num">{{ topTotalList.cuttime_total }}</div>
          </div>
        </div>
        <div class="over-num-item">
          <div class="icon-box box3">
            <img class="icon" src="@/assets/img/page/index_top_icon3.png" alt />
          </div>
          <div class="o-text">
            <div class="name">累计暂停时间 Total down time</div>
            <div class="name">小时 : 分钟 H : mm</div>
            <div class="o-num">{{ topTotalList.pause_total }}</div>
          </div>
        </div>
        <div class="over-num-item">
          <div class="icon-box box4">
            <img class="icon" src="@/assets/img/page/index_top_icon4.png" alt />
          </div>
          <div class="o-text">
            <div class="name">平均OEE Average OEE</div>
            <div class="name"></div>
            <div class="o-num">
              {{ handleCountTwo(topTotalList.oee * 100) }}%
            </div>
          </div>
        </div>
      </div>
      <!-- 图表 -->
      <div class="view-chart-box">
        <div class="chart-item">
          <div class="title">机器OEE Machine OEE</div>
          <div class="charts-wrap" id="oeeChart"></div>
        </div>
        <div class="chart-item">
          <div class="title">机器产量 Machine Output : 片 Piece</div>
          <div class="charts-wrap" id="outputChart"></div>
        </div>
        <div class="chart-item">
          <div class="title">型体产量 Output by model : 片 Piece</div>
          <div class="charts-wrap three-box">
            <div id="styleChart" class="style-box"></div>
            <div class="style-box style-num-box">
              <div class="head-title">
                <div class="text name">
                  <span class="chinese">型体</span><br />
                  <span class="english">Model</span>
                </div>
                <div class="text num">
                  <span class="chinese">产量</span><br />
                  <span class="english">Output</span>
                </div>
                <div class="text pro">
                  <span class="chinese">占比</span><br />
                  <span class="english">Percentage</span>
                </div>
              </div>
              <div class="head-body">
                <div class="line" v-for="(item, index) in pieData" :key="index">
                  <div class="text name">
                    <div class="dot" :style="'background:' + item.color"></div>
                    {{ item.name }}
                  </div>
                  <div class="text num">{{ item.Output }}</div>
                  <div class="text pro">{{ item.value }}%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chart-item">
          <div class="title">时间段产量 Output by period : 片 Piece</div>
          <div class="charts-wrap" id="timeChart"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reqMachineList, reqOutputList, reqOutputByTimes } from '@/api/index';
import dateSearch from '@/components/dateSearch.vue';
import topDate from '@/components/topDate.vue';
import { formatSeconds } from '@/utils/index.js';
export default {
  components: {
    dateSearch,
    topDate,
  },
  data() {
    return {
      pauseTimeData: [],
      dateType: 0, // 0:当天  1：当周  2：当月 3:自定义
      machineXData: [], //机器产量//可以用machinexdataforoee\machinexdataforoutput
      machineYDataOne: [], //目标
      machineYDataTwo: [], //实际
      machineTotal: 0, //机器产量总
      pieData: [], //形体产量data
      threeTitle: '', //形体产量总
      queryParam: {
        BeginTime: '',
        EndTime: '',
		Shift: 0,
      },
      topTotalList: {
        reality_actual_count: 0,
        cuttime_total: 0,
        pause_total: 0,
        oee: 0,
      }, //顶部4个
      timesXData: [], //时间段产量x
      timesYData: [], //时间段产量y
      oeeXData: [], //机器oeex
      oeeYDataOne: [], //机器oee目标y
      oeeYDataTwo: [], //机器oee实际y
      timer: null,
      deviceNum: 0, //设备数
    };
  },
  created() {
    let _this = this;
    _this.dateType = this.$store.state.searchType;
    _this.queryParam.BeginTime = this.$store.state.startTimes;
    _this.queryParam.EndTime = this.$store.state.endTimes;
	_this.queryParam.Shift = this.$store.state.shift;
	console.log("shift:",this.$store.state.shift)

    _this.getMachineList();
    _this.getOutputList();
    _this.getByTimesList();

    _this.timer = setInterval(function () {
      _this.handleRefresh();
    }, 60000);
  },
  methods: {
    handleDigitalFlop(num) {
      let config1 = {
        number: [num],
        content: '{nt}',
        style: {
          fill: '#3f4653',
          fontSize: 28,
          textAlign: 'left',
          fontWeight: 'bold',
        },
      };
      return config1;
    },
    handleSearch(val) {
      if (val) {
        this.dateType = val.dateType;
        this.queryParam.BeginTime = val.start_time;
        this.queryParam.EndTime = val.end_time;
		this.queryParam.Shift = val.shift || 0;
      }
      this.handleRefresh();
    },
    handleRefresh() {
      //清空已有数据重新触发数据获取逻辑
      this.topTotalList.reality_actual_count = 0;
      this.topTotalList.cuttime_total = 0;
      this.topTotalList.pause_total = 0;
      this.topTotalList.oee = 0;
      this.timesXData = []; //时间段产量x
      this.timesYData = []; //时间段产量y
      this.oeeXData = []; //机器oeex
      this.oeeYDataOne = []; //机器oee目标y
      this.oeeYDataTwo = [];

      this.machineXData = []; //机器产量
      this.machineYDataOne = []; //目标
      this.machineYDataTwo = []; //实际
      this.machineTotal = 0; //机器产量总
      this.pieData = []; //形体产量data
      this.threeTitle = '';

      // 形体产量
      this.getOutputList();
      //  机器产量
      this.getMachineList();
      //  时间段产量
      this.getByTimesList();
    },
    getMachineList() {
      let params = {
        DateType: this.dateType,
        MachineNO: '',
        BeginTime: this.queryParam.BeginTime,
        EndTime: this.queryParam.EndTime,
		Shift: this.queryParam.Shift || 0,
      };
      reqMachineList(params)
        .then((res) => {
          if (res.Data.AllMachineTotal) {
            this.topTotalList = res.Data.AllMachineTotal;
            this.topTotalList.cuttime_total = formatSeconds(
              this.topTotalList.cuttime_total,
            );
            this.topTotalList.pause_total = formatSeconds(
              this.topTotalList.pause_total,
            );
          }

          // 创建 machine_no 到 name 的映射表
          // name在infos中,machine_no在infos和totals中都有,所以要name就需要映射

          let nameMap = {};
          if (res.Data.MachineInfos) {
            res.Data.MachineInfos.forEach((info) => {
              nameMap[info.machine_no] = info.name; // 将 machine_no 映射到 name
            });
          }

          let arr = res.Data.MachineTotals;
          if (arr.length >= 1) {
            this.deviceNum = arr.length;
            arr.forEach((item) => {
              // 使用 name 替代 machine_no 作为 X 轴数据
              let machineName = nameMap[item.machine_no] || item.machine_no; // 如果没有 name，默认显示 machine_no
              this.machineXData.push(machineName); // 将 name 添加到 x 轴
              //this.machineXData.push(item.machine_no);
              this.machineYDataOne.push(item.target_order_count); //目标-产量
              this.machineYDataTwo.push(item.reality_actual_count); //实际

              // 稼动率
              this.oeeXData.push(machineName); // 将 name 添加到 OEE 的 x 轴
              //this.oeeXData.push(item.machine_no); //四舍如无保留两位小数
              this.oeeYDataOne.push(this.handleCountTwo(item.target_oee * 100));
              this.oeeYDataTwo.push(this.handleCountTwo(item.oee * 100));
              this.pauseTimeData.push(item.pause_total);
            });
          } else {
            this.deviceNum = 0;
          }
          this.getCharts();
          this.getChartsTwo();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleCountTwo(num) {
      if (num) {
        let count = Number(num).toFixed(2);
        return count;
      } else {
        return 0;
      }
    },
    getOutputList() {
      // pieData
      let params = {
        DateType: this.dateType,
        BeginTime: this.queryParam.BeginTime,
        EndTime: this.queryParam.EndTime,
		Shift: this.queryParam.Shift || 0,
      };
      reqOutputList(params)
        .then((res) => {
          if (res.Code == 0) {
            let colorList = [
              '#FFAA00',
              '#7953FF',
              '#038EFF',
              '#03DAC5',
              '#65B581',
              '#FFCE34',
              '#FD665F',
            ];
            let count = 0;
            let dataArr = res.Data.StyleOutputTotal;
            let newArr = [];
            if (dataArr.length >= 1) {
              dataArr.forEach((item) => {
                if (count == 7) {
                  count = 0;
                }
                newArr.push({
                  Output: item.output_count,
                  name: item.style_name,
                  color: colorList[count],
                  value: (
                    (item.output_count / res.Data.all_output_count) *
                    100
                  ).toFixed(2), //四舍如无保留两位小数
                });
                count++;
              });
            }
            // this.pieData=JSON.parse(JSON.stringify(newArr))
            this.threeTitle = res.Data.all_output_count;
            this.pieData = newArr;

            this.getChartsThree();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getByTimesList() {
      let params = {
        DateType: this.dateType,
        BeginTime: this.queryParam.BeginTime,
        EndTime: this.queryParam.EndTime,
		Shift: this.queryParam.Shift || 0,
      };
      reqOutputByTimes(params)
        .then((res) => {
          if (res.Code == 0) {
            let arr = res.Data.MachineOutputTotal;
            if (arr.length >= 1) {
              arr.forEach((item) => {
                this.timesXData.push(`${item.begin_time}-${item.end_time}`);
                this.timesYData.push({
                  label: item.rule_no,
                  value: item.output_count,
                }); //目标
              });
            }
            this.getChartsFour();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCharts() {
      // 柱状图1
      const chartBox = this.$echarts.init(document.getElementById('oeeChart'));
      let scrollPosition;
      if (this.oeeXData.length > 4) {
        scrollPosition = 50;
      } else {
        scrollPosition = 96;
      }

      let option = {
        // backgroundColor: "#323a5e",
        /*
        tooltip: {//数据提示框
          trigger: "axis",
          
          //trigger: "none",//关闭默认触发方式
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
            //show:true,//确保颜色提示小圆点显示            
          },                                   
            formatter: function(p) {
              //let tooltipContent = '';
            let tooltipContent = p[0].axisValueLabel + '<br>'; // 显示机器设备信息
              
            p.forEach(function(item) {
                //let formattedValue = item.value.toLocaleString();//使用tolocalstring格式化数据
                // 添加小圆点  tooltipContent += item.marker; 这是5.x以上版本才能用

                let color;
                //tooltipContent += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + color + ';"></span>';
                
                if (item.seriesName === "目标稼动率 Target availability") {
                    //tooltipContent += colorCircle + '目标稼动率: ' + item.value  + '<br>' + colorCircle +'Target availability: ' + item.value  + '<br>';
                    color = item.color;//显示小圆点颜色标识
                    //下面这行放在英文前可实现英文左侧有颜色标识，圆形小圆点弧度由border-radius负责实现，当宽度和高度相等时，半径大小大于或等于宽度或高度的一半时元素就是圆形
                    tooltipContent += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + color + ';"></span>';
                    tooltipContent +=  "目标稼动率: " + item.value  + '<br>';
                    tooltipContent +=  "Target availability: " + item.value  + '<br>';
                } else if (item.seriesName === "实际稼动率 Actual availability") {
                    //tooltipContent += colorCircle + '实际稼动率: ' + item.value  + '<br>' + colorCircle +'Actual availability: ' + item.value  + '<br>';
                    color = "#2579FF";//用实际稼动率的柱状体的渐变色的下端深色作为提示小圆点颜色，或者上端浅色#4ABAFF
                    tooltipContent += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + color + ';"></span>';
                    tooltipContent +=  "实际稼动率: " + item.value  + '<br>';
                    tooltipContent +=  "Actual availability: " + item.value  + '<br>';
                }
            });                                
            return tooltipContent;
            },                                       
        },
        */
        grid: {
          left: '0',
          right: '0',
          bottom: '12%',
          top: '16%',
          containLabel: true,
        },
        legend: {
          data: [
            '目标稼动率 Target availability',
            '实际稼动率 Actual availability',
          ],
          //data: ["实际稼动率 Actual availability"],
          right: 0,
          top: 0,
          textStyle: {
            color: '#878C93',
          },
          itemWidth: 12,
          itemHeight: 10,
          // itemGap: 35
        },
        xAxis: {
          type: 'category',
          data: this.oeeXData,
          axisLine: {
            lineStyle: {
              color: '#878C93',
            },
          },
          axisLabel: {
            // interval: 0,
            // rotate: 40,
            textStyle: {
              fontFamily: 'Microsoft YaHei',
            },
          },
        },

        yAxis: {
          min: 0, //最小百分比
          max: 100, //最大百分比
          type: 'value',
          name: '   单位 Unit (%)',
          nameGap: 35,
          nameTextStyle: { color: '#666666' },
          axisTick: { show: false },
          axisLabel: {
            show: true,
            interval: 0, // 使x轴文字显示全
            color: '#666666',
            formatter: '{value}%', //y轴数值，带百分号
          },
          axisLine: { show: false, lineStyle: { color: '#dddddd' } },
          splitLine: { lineStyle: { type: 'dashed', color: '#eeeeee' } },
        },
        dataZoom: [
          {
            type: 'slider',
            show: true, //truer
            height: 20,
            xAxisIndex: [0],
            bottom: '5%',
            // start: 0,
            startValue: 0,
            endValue: 14, // 一次性展示15个
            realtime: true, //是否实时更新
            brushSelect: false, //刷选功能
            // end: scrollPosition,//底部滑块显示位置
            // handleSize: "110%",

            handleStyle: {
              color: '#d3dee5',
            },
            textStyle: {
              color: 'transparent', // 设置文本颜色为透明，隐藏文本
              //color: "#000",本
            },
            backgroundColor: '#f1f1f1',
            selectedDataBackground: '#FCEBDC',
            dataBackground: {
              areaStyle: {
                color: '#2579FF',
              },
            },
            fillerColor: '#FCEBDC',
          },
        ],
        series: [
          {
            name: '目标稼动率 Target availability',
            type: 'bar',
            // stack: "总量",
            // barMaxWidth: 20,
            barWidth: 20,
            // barGap: "10%",
            barCategoryGap: '10%',
            z: '-1',
            barGap: '-100%',
            itemStyle: {
              normal: {
                color: '#C5E1FF',
                barBorderRadius: 2,
                label: {
                  show: false,
                  textStyle: {
                    color: '#2579FF',
                  },
                  position: 'top',
                  formatter: function (p) {
                    return '目标：' + p.value + '%';
                  },
                },
              },
            },
            data: this.oeeYDataOne,
          },
          {
            name: '实际稼动率 Actual availability',
            type: 'bar',
            // stack: "总量",
            // barMaxWidth: 20,
            barCategoryGap: '10%',
            barWidth: 20,
            itemStyle: {
              normal: {
                //渐变色，tooltip中不能直接显示但柱状体可以
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#4ABAFF',
                  },
                  {
                    offset: 1,
                    color: '#2579FF',
                  },
                ]),
                barBorderRadius: 2,
                label: {
                  show: true,
                  //position: "inside",
                  position: 'insidetop',
                  align: 'center',
                  offset: [8, -28],
                  //verticalAlign:"middle",
                  textStyle: {
                    color: '#878C93',
                    fontSize: 15,
                    fontWeight: 'bold',
                  },
                  formatter: (params) => {
                    let availability =
                      parseFloat(params.value).toFixed(1) + '%';
                    let pauseTime = this.pauseTimeData[params.dataIndex];
                    let hours = Math.floor(pauseTime / 3600);
                    let minutes = Math.floor((pauseTime % 3600) / 60);
                    return `${availability}\n${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
                  },
                },
              },
            },
            data: this.oeeYDataTwo,
          },
        ],
      };

      chartBox.clear();
      chartBox.setOption(option);

      // 添加mousemove事件监听
      chartBox.getZr().on('mousemove', function (p) {
        let pointInPixel = [p.offsetX, p.offsetY];
        if (chartBox.containPixel('grid', pointInPixel)) {
          let pointInGrid = chartBox.convertFromPixel('grid', pointInPixel);
          let xIndex = Math.floor(pointInGrid[0]);
          chartBox.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: xIndex, // 这里可以根据需要调整显示的柱状体
          });
        }
      });
    },

    getChartsTwo() {
      let _this = this;
      // 柱状图2
      const chartBox = this.$echarts.init(
        document.getElementById('outputChart'),
      );
      let scrollPosition;
      if (this.machineXData.length > 6) {
        scrollPosition = 50;
      } else {
        scrollPosition = 96;
      }
      let option = {
        // backgroundColor: "#323a5e",
        /*
        tooltip: {
          trigger: "axis",                  
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
                
        formatter: function(p) {
          //let tooltipContent = '';
          let tooltipContent = p[0].axisValueLabel + '<br>'; // 显示机器设备信息
          p.forEach(function (item) {
              //let formattedValue = item.value.toLocaleString();//使用tolocalstring格式化数据
              let color = item.color;//显示颜色小圆点标识
              tooltipContent += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + color + ';"></span>';
              //英文加颜色标识就拆开，每个前单独加span style
              if (item.seriesName === '目标产量 Target output' && typeof item.value !== 'undefined') {
                  tooltipContent += '目标产量: ' + item.value.toLocaleString() + '<br>' +'Target Output: ' + item.value.toLocaleString() + '<br>';
            } else if (item.seriesName === '实际产量 Actual output' && typeof item.value !== 'undefined') {
                  tooltipContent += '实际产量: ' + item.value.toLocaleString() + '<br>' +'Actual Output: ' + item.value.toLocaleString() + '<br>';
            }                           
          });
          return tooltipContent;
        },            
      },
      */
        grid: {
          left: '0',
          right: '0',
          bottom: '12%',
          top: '16%',
          containLabel: true,
        },
        legend: {
          data: ['目标产量 Target output', '实际产量 Actual output'],
          //data: ["实际产量 Actual output"],
          right: 0,
          top: 0,
          textStyle: {
            color: '#878C93',
          },
          itemWidth: 12,
          itemHeight: 10,
          // itemGap: 35
        },
        xAxis: {
          type: 'category',
          data: _this.machineXData,
          axisLine: {
            lineStyle: {
              color: '#878C93',
            },
          },
          axisLabel: {
            // interval: 0,
            // rotate: 40,
            textStyle: {
              fontFamily: 'Microsoft YaHei',
            },
          },
        },

        yAxis: {
          // min: 0, //最小百分比
          // max: 100, //最大百分比
          type: 'value',
          // name: "单位（%）",
          // nameGap: 35,
          nameTextStyle: { color: '#666666' },
          axisTick: { show: false }, //不显示坐标轴刻度线
          axisLabel: {
            show: true, //显示坐标轴
            interval: 0, // 使x轴文字显示全
            color: '#666666',
            // formatter: "{value}%", //y轴数值，带百分号
          },
          axisLine: { show: false, lineStyle: { color: '#dddddd' } },
          splitLine: { lineStyle: { type: 'dashed', color: '#eeeeee' } },
        },
        dataZoom: [
          {
            type: 'slider',
            show: true,
            height: 20,
            xAxisIndex: [0],
            bottom: '5%',
            startValue: 0,
            endValue: 14, // 一次性展示15个
            realtime: true, //是否实时更新
            brushSelect: false, //刷选功能
            handleStyle: {
              color: '#d3dee5',
            },
            textStyle: {
              color: 'transparent', //#000
            },
            backgroundColor: '#f1f1f1',
            selectedDataBackground: '#FCEBDC',
            dataBackground: {
              areaStyle: {
                // color: "#2579FF",
                color: '#FF4925',
              },
            },
            fillerColor: '#FCEBDC',
          },
        ],
        series: [
          {
            name: '目标产量 Target output',
            type: 'bar',
            // stack: "总量",
            // barMaxWidth: 20,
            barWidth: 20,
            // barGap: "10%",
            z: '-1',
            barGap: '-100%',
            // barCategoryGap:'10%',
            itemStyle: {
              normal: {
                color: '#FDD065',
                barBorderRadius: 2,
                label: {
                  show: false,
                  textStyle: {
                    color: '#fff',
                  },
                },
              },
            },

            data: _this.machineYDataOne,
          },
          {
            name: '实际产量 Actual output',
            type: 'bar',
            // barCategoryGap:'10%',
            barWidth: 20,
            itemStyle: {
              normal: {
                color: '#FF4925',
                barBorderRadius: 2,
                label: {
                  show: true,
                  //position: "top",
                  position: 'insidetop',
                  align: 'center',
                  offset: [8, -12],
                  //verticalAlign:"middle",
                  textStyle: {
                    color: '#878C93', // 设置标签的颜色
                    fontWeight: 'bold',
                    fontSize: 15,
                  },
                  formatter: function (p) {
                    return p.value.toLocaleString(); // 使用逗号分隔数值
                  },
                },
              },
            },
            data: _this.machineYDataTwo,
          },
        ],
      };
      chartBox.clear(); //原来是注释的
      chartBox.setOption(option);

      // 添加mousemove事件监听，实现固定tooltip效果
      chartBox.getZr().on('mousemove', function (p) {
        let pointInPixel = [p.offsetX, p.offsetY];
        if (chartBox.containPixel('grid', pointInPixel)) {
          let pointInGrid = chartBox.convertFromPixel('grid', pointInPixel);
          let xIndex = Math.floor(pointInGrid[0]);
          // 只有当tooltip不可见时才触发显示tooltip
          chartBox.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: xIndex,
          });
          chartBox.dispatchAction({
            type: 'showTip',
            seriesIndex: 1, // 选择实际产量的series
            dataIndex: xIndex,
          });
        }
      });
    },

    getChartsThree() {
      let _this = this;
      const chartBox = this.$echarts.init(
        document.getElementById('styleChart'),
      );
      // let colorList = ["#FFAA00", "#7953FF", "#038EFF", "#03DAC5",'#65B581', '#FFCE34', '#FD665F'];
      let option = {
        title: {
          text: _this.threeTitle,
          x: 'center',
          y: 'center',
          textStyle: {
            fontSize: 20,
          },
          // subtext: '(占所有数据的的' + (arr[1] * 10000 / 100).toFixed(2) + '%)',
          subtext: '总产量 Total output',
          subtextStyle: {
            color: '#999',
            fontSize: 12,
          },
        },
        tooltip: {
          trigger: 'item',
          formatter(params) {
            //鼠标移入数据显示内容
            return `<div>${params.data.name}<br/>产量 Output：${params.data.Output}<br/>占比 Percentage：${params.data.value}%</div>`;
          },
        },
        series: [
          {
            type: 'pie',
            // minAngle: 1,
            center: ['50%', '50%'],
            radius: ['60%', '92%'],
            clockwise: false,
            avoidLabelOverlap: false,
            hoverOffset: 15,
            borderWidth: 5,
            emphasis: {
              itemStyle: {
                borderColor: '#fff',
                borderWidth: 5,
              },
            },
            itemStyle: {
              normal: {
                borderWidth: 4,
                // borderColor: "#fff",
                label: {
                  show: false, //是否显示外围提示
                },
                color: function (params) {
                  return params.data.color;
                },
              },
            },
            labelLine: {
              normal: {
                length: 20,
                length2: 30,
                lineStyle: {
                  width: 1,
                },
              },
            },
            data: _this.pieData,
          },
        ],
      };
      // chartBox.clear();
      chartBox.setOption(option);
    },
    getChartsFour() {
      // 面积图
      const chartBox = this.$echarts.init(document.getElementById('timeChart'));

      let scrollPosition;
      if (this.timesXData.length > 8) {
        scrollPosition = 50;
      } else {
        scrollPosition = 96;
      }

      let option = {
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            // return p.value > 0 ? p.value : "";
            return (
              params[0].data.label +
              '<br/>时间 Time：' +
              params[0].name +
              '<br/>产量 Output：' +
              params[0].value
            );
          },
        },
        grid: {
          top: '10%',
          left: '0%',
          right: '2%',
          bottom: '15%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.timesXData,
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
            },
          },
          axisLine: { show: false, lineStyle: { color: '#878C93' } },
        },
        dataZoom: [
          {
            type: 'slider',
            show: true,
            height: 20,
            xAxisIndex: [0],
            bottom: '5%',
            startValue: 0,
            endValue: 9, // 一次性展示10个
            handleStyle: {
              color: '#d3dee5',
            },
            textStyle: {
              color: 'transparent', //#000
            },
            backgroundColor: '#f1f1f1',
            selectedDataBackground: '#FCEBDC',
            dataBackground: {
              areaStyle: {
                color: '#2579FF',
              },
            },
            fillerColor: '#FCEBDC',
          },
        ],
        series: [
          {
            data: this.timesYData,
            type: 'line',
            // smooth: true,
            symbolSize: 8,
            symbol: 'circle',
            lineStyle: {
              width: 2,
            },
            itemStyle: {
              color: '#FFAF24', // 设置线条颜色
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(255, 175, 36, 0.48)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(255, 172, 94, 0.05)',
                    },
                  ],
                  false,
                ),
              },
            },
            label: {
              show: true, // 显示数据标签
              position: 'top', // 将数据标签放置在折线图顶部
              textStyle: {
                color: '#878C93', // 设置标签的颜色
                fontWeight: 'bold',
                fontSize: 15,
              },
              formatter: function (p) {
                return p.value.toLocaleString(); // 使用逗号分隔数值
              },
            },
          },
        ],
      };
      // chartBox.clear();
      chartBox.setOption(option);
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.box-wrap {
  width: 100%;
  height: 100%;
  background: #f4f5f7;
  overflow: hidden;
  .top-box {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    background: #ffffff;
  }
  .chart-box {
    height: 1020px;
    // border: 1px solid #f00;
    .top-num-box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      padding: 22px 30px 11px;
      .over-num-item {
        width: 387px;
        height: 131px;
        border-radius: 10px;
        box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.1);
        display: flex;

        align-items: center;
        background: #fff;
        .icon-box {
          width: 70px;
          height: 68px;
          margin: 0 15px 0 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 24px;
          .icon {
            display: block;
            width: 48px;
          }
        }
        .box1 {
          background: #e0efff;
          box-shadow: 0px 4px 10px 0px #e0efff;
        }
        .box2 {
          background: #d1f4ef;
          box-shadow: 0px 4px 10px 0px #d1f4ef;
        }
        .box3 {
          background: #ffebcd;
          box-shadow: 0px 4px 10px 0px #fff0d5;
        }
        .box4 {
          background: #ede6ff;
          box-shadow: 0px 4px 10px 0px #ede6ff;
        }
        .o-text {
          .name {
            height: 20px;
            font-size: 14px;
            color: #7f8692;
            margin-bottom: 8px;
          }
          .o-num {
            font-size: 28px;
            color: #3f4653;
            font-weight: bold;
            height: 40px;
            line-height: 40px;
          }
        }
      }
    }
    // 图表
    .view-chart-box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 30px 0;
      .chart-item {
        width: 796px;
        height: 404px;
        border-radius: 10px;
        box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.1);
        background: #ffffff; //ffffff
        padding: 25px 18px 0;
        margin: 11px 0;
        .title {
          height: 26px;
          line-height: 10px;
          font-size: 18px;
          font-weight: bold;
          color: #3f4653;
          position: relative;
          padding-left: 12px;
          &::before {
            position: absolute;
            content: '';
            width: 6px;
            height: 18px;
            border-radius: 40px;
            background: #f5812d;
            left: 0;
            top: -4px;
          }
        }
        .charts-wrap {
          width: 100%;
          height: 345px;
          //background: #3b3ba1;
          //   border: 1px solid #f00;
        }
        .three-box {
          display: flex;
          //background: #7d41dc;
          justify-content: space-between;
          .style-box {
            width: 400px;
            // border: 1px solid #f00;
          }
          .style-num-box {
            overflow: hidden;
            padding-top: 20px;
            //background: #242323;
            .head-title {
              display: flex;
              //background-color: #f5812d;
              justify-content: space-between;
              height: 60px;
              .text {
                font-size: 16px;
                color: #9ea4ae;
                padding: 8px 0;
                //text-align: center; /* 居中对齐 */
              }
            }
            .name {
              width: 210px;
              padding-left: 10px !important;
              //background: #e29595;
            }
            .num {
              width: 80px;
              background: #ffffff;
            }
            .pro {
              // width: 50px;
              width: 95px;
              //text-align: right!important;
            }
            .text .chinese,
            .text .english {
              display: block; /* 将中英文换行显示 */
              white-space: nowrap; /* 防止中英文换行 */
            }
            .head-body {
              width: 100%;
              height: 244px;
              overflow-y: scroll;
              overflow-x: hidden;
              &::-webkit-scrollbar {
                width: 0px;
              }
              &::-webkit-scrollbar-track {
                background-color: #f5f5f5;
              }
              &::-webkit-scrollbar-thumb {
                border-radius: 4px; /* 控制 滑块 的 圆角 大小 */
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              }

              .line {
                font-size: 16px;
                color: #808792;
                display: flex;
                justify-content: space-between;
                padding: 11px 0;
                .name {
                  display: flex;
                  align-content: center;
                  padding-left: 0 !important;
                }
                .num {
                  text-align: left;
                }
                .dot {
                  display: inline-block;
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  //background: #f5812d;
                  margin-right: 5px;
                }
                .text {
                  color: #808792;
                  font-size: 16px;
                  padding: 8px 0;
                  text-align: left;
                }
                &:nth-child(2) {
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
